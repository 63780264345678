/**
 * 获取过期时间
 */
const GetExpireTime = function (createTime, expireTime) {
  const _object = {}
  let no = ""
  let ret = ""
  if (expireTime == 0) return no
  createTime = createTime.replace(/-/g, "/");
  createTime = new Date(createTime);
  let nowTime = new Date();
  let disparity = nowTime.getTime() - createTime.getTime();

  // 转为分钟数的时候，可能会出现精度丢失;你需要注意下
  let min = Math.round(disparity / 1000);
  let difference = (expireTime * 60) - min
  if (difference <= 0) return no
  _object.number = difference
  let day = parseInt(difference / 60 / 24);
  let hour = parseInt(difference / 60 % 24);
  let mi = parseInt(difference % 60);

  if (day > 0) {
    ret = day + "天";
  }
  if (hour > 0) {
    ret += hour + "小时";
  }
  if (mi > 0) {
    ret += parseFloat(mi) + "分钟";
  }
  _object.string = ret
  //三元运算符 传入的分钟数不够一分钟 默认为0分钟，else return 运算后的value 

  return _object

}
export default {
  GetExpireTime: GetExpireTime
}
