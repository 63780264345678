<template>
  <div v-if="payOrderInfo">
    <div class="payAmount-content">
      <div class="payAmount-timestamp" v-if="countDownTimestamp">
        订单将在
        <CountDown :time="countDownTimestamp"></CountDown>
        之后关闭，请尽快完成支付
      </div>
      <div class="payAmount-timestamp" v-else> 订单已过期 </div>
    </div>
    <component :ref="payWay" :is="payWay" :payOrderInfo="payOrderInfo"></component>
    <ul class="plus-ul">
      <!-- 支付板块 -->
      <li class="payType" v-for="item in payWayList" :key="item.wayCode" @click="choosePayType(item.wayCode)">
        <!-- 支付金额板块 -->
        <div class="img-div">
          <div class="flex">
            <img :src="wayCodeUtils.getImgIcon(item.wayCode)" alt="" />
            <div class="div-text">{{ item.wayName }}</div>
          </div>
          <div class="choose" :style="item.wayCode == wayCode ? `background-color: ${color}` : ''"></div>
        </div>
      </li>

    </ul>
    <div class="bnt-pay" v-if="countDownTimestamp">
      <div class="bnt-pay-text" :style="{ backgroundColor: color }" @click="pay">
        付款
      </div>
    </div>
  </div>
</template>

<script>
import wx from './Wxpay.vue';
import zfb from './Alipay.vue';
import ysf from './Ysfpay.vue';
import { getPayOrderInfo } from "@/api/api";
import config from "@/config";
import wayCodeUtils from "@/utils/wayCode";
import utils from "@/utils/utils";
import CountDown from './count-down.vue'
export default {
  components: {
    wx,
    zfb,
    ysf,
    CountDown
  },
  data: function () {
    return {
      payWay: wayCodeUtils.getPayWay().name, 
      payOrderInfo: null, //订单信息
      wayCode: '',
      color: wayCodeUtils.getPayWay().color,
      countDownTimestamp: 0,
      payWayList: []
    };
  },

  mounted() {
    this.setPayOrderInfo(); //获取订单信息 & 调起支付插件
  },

  //如果未获取到路由配置,跳转到对应的错误页面
  methods: {
    setPayOrderInfo(isAutoPay) {
      this.$loading.show({
        backgroundColor: "rgb(237,237,237)",
      });
      let payOrderNo = config.cachePayParams || this.$route.query.payOrderNo
      getPayOrderInfo(payOrderNo)
        .then((res) => {
          //支付状态: 0-订单生成, 1-支付中, 2-支付成功, 3-支付失败, 4-已撤销, 5-已退款, 6-订单关闭
          if (res.data.status !== 0 && res.data.status !== 1) {
            //订单不是未支付，说明订单异常
            let message;
            switch (res.data.status) {
              case 2:
                message = "已支付";
                break;
              case 3:
                message = "支付失败";
                break;
              case 4:
                message = "已撤销";
                break;
              case 5:
                message = "已退款";
                break;
              case 6:
                message = "订单关闭";
                break;
              default:
                message = "待支付";
                break;

            }
            return alert(message);
          }
          this.countDownTimestamp = utils.GetExpireTime(
            res.data.createTime,
            res.data.expireTime
          ).number
          if (res.data.payInterface && res.data.payInterface.length) {
            let list = []
            res.data.payInterface.forEach(item => {
              item.payWays.forEach(items => {
                list.push(items)
              })
            });
            
            console.log(list);
            list = wayCodeUtils.getPayWayList(list);  
            this.payWayList = list
            this.wayCode = list[0].wayCode
          }

          this.payOrderInfo = res.data;

          if (isAutoPay) {
            this.pay();
          }
        })
        .catch((err) => {
          this.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: err.message },
          });
        })
        .finally(() => {
          this.$loading.hide();
        });
    }, 
    choosePayType(wayCode) {
      this.wayCode = wayCode
    },
    pay() {
      if (!this.wayCode) {
        return alert('请先选择支付方式')
      }
      this.$refs[this.payWay].pay(config.cachePayParams, this.wayCode)
    }
  },
};
</script>
<style lang="css" scoped>
@import "./pay.css";

.payType {
  border-radius: 10px;
  margin-bottom: 15px;
}

.payAmount-content {
  text-align: center;
}

.payAmount-content .payAmount-timestamp {
  color: #797c81;
  background: #fdf6ec;
  border-top: 1px solid #ff9900;
  border-bottom: 1px solid #ff9900;
  padding: 4px 0;
  margin: 2px 0 25px;
  font-size: 28px;
}

.payAmount-content .payAmount-text {
  font-size: 28px;
  margin-bottom: 10px;
}

.payAmount-content .payAmount-amount {
  font-size: 50px;
  color: #e8791b;
}
</style>
